<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">smart-whats.com</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">تأكيد رقم الهاتف</h2>
        <h5>
          تم ارسال كود التفعيل على الواتساب <br /><br />على<strong class="g">
            {{ user.phone }}</strong
          >
          <br>
          <small>اذا لم يصلك الكود تأكد ان الرقم عليه واتساب وانه مكتوب بالصيغة الدولية</small>
        </h5>
        <p class="mb-2">
          <button class="btn btn-sm" @click="$router.push('/logout')">
            الرقم خطأ؟ <u>تسجيل الخروج</u>
          </button>
        </p>
        <div class="form-group">
          <input
            type="number"
            v-model="code"
            class="form-control form-control-lg text-center"
            autofocus
            placeholder="كود التفعيل هنا..."
          />
        </div>
        <button class="btn btn-block btn-primary" @click="activateNow()">
          <i class="fa fa-check"></i>
          تفعيل الحساب الآن
        </button>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      code: "",
    };
  },
  methods: {
    activateNow() {
      var g = this;
      $.post(api + "/user/auth/activate", {
        code: this.code,
        jwt: this.user.jwt,
      })
        .then(function (response) {
          response = JSON.parse(response);
          if (response.status == 100) {
            window.location = "/";
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: response.response,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          alert("Error:" + e);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
